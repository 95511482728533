<template>
  <div class="settings container">
    <div class="card">

      <div class="row">
        <div class="col-12">
          <div class="card m-b-0">
            <div class="card-header">
              <h5>{{ $t('settings.history') }}</h5>
            </div>
            <div class="card-body">
              <p>{{$t('settings.storageTitle')}}</p>
              <p>{{ $t('ui.used_storage') }}: {{ totalSize }}</p>
              <b-button type="button" class="btn btn-primary" @click="opedDialog" >{{$t('settings.Clear_cache') }}</b-button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card m-b-0">
            <div class="card-header">
              <h5>{{ $t('addVerification.account_to_confirm') }}</h5>
            </div>
            <div class="card-body">
              <div style="display: flex; align-items: center" class="m-b-20">
                <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" fill="#2AABEE" viewBox="0 0 496 512"><path d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"/></svg>

                <p v-if="user.telegram_id"   style=" margin-bottom: 0; margin-left: 6px" v-html="$t('addVerification.sendCode')+': '+ $t('settings.account_is_set')"></p>
                <p v-else style=" margin-bottom: 0; margin-left: 6px" v-html="$t('addVerification.sendCode')+': '+$t('settings.account_is_not_set')"></p>
              </div>
              <p>
                <router-link class="btn btn-primary" :to="{name: 'ChangeTelegramId'}">
                  <span v-if="user.telegram_id">{{$t('settings.changeTelegramID')}}</span>
                  <span v-else>{{$t('settings.setTelegramID')}}</span>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <b-modal centered ref="clean-history" hide-footer :title="this.$t('side_menu.search_history')">
      <h4>{{ this.$t('user.clear_cache_message') }}</h4>
      <b-input :placeholder="$t('settings.login')" expanded v-model="inputValue"></b-input>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" :disabled="inputValue !== user.login" block @click="confirmDialog">{{ $t("settings.Delete") }}</b-button>
        <b-button class="mt-2" variant="secondary" block  @click="hide">{{ $t("modal.cancel") }}</b-button>
      </div>
    </b-modal>



  </div>
</template>

<!--suppress JSCheckFunctionSignatures -->
<script>
import {mapGetters} from "vuex";
import CountrySelect from "../components/settings/CountrySelect";
import clearCache from "@/mixins/clearCache";
import AddVerificationForm from "@/components/AddVerificationForm.vue";

export default {
  components: {CountrySelect, AddVerificationForm},
  mixins: [clearCache],
  data() {
    return {
      hlr: localStorage.getItem('hlr') ? Boolean(Number(localStorage.getItem('hlr'))) : false,
      searchServices: localStorage.getItem('search_services')
          ? Boolean(Number(localStorage.getItem('search_services'))) : true,
      searchAlgorithms: localStorage.getItem('search_algorithms')
          ? Boolean(Number(localStorage.getItem('search_algorithms'))) : true,
      inputValue: "",
      sendTelegramCode: false
    }
  },
  methods: {


    opedDialog(){
      this.$refs['clean-history'].show();
    },
    hide(){
      this.$refs['clean-history'].hide();
    },

    async confirmDialog(){
      await this.$refs['clean-history'].hide();
      await this.clearCache();
      await this.$store.dispatch('ui/getFreeSpaceInfo');
    }

  },
  computed: {
    ...mapGetters('auth', ['roles', 'user', 'verificationChannels']),
    ...mapGetters('ui', ['percentTotal', 'totalSize'])
  },
  watch: {
    hlr(val) {
      if(val) {
        localStorage.setItem('hlr', 1);
      } else {
        localStorage.setItem('hlr', 0);
      }
    },
    searchServices(val) {
      if(val) {
        localStorage.setItem('search_services', 1);
      } else {
        localStorage.setItem('search_services', 0);
      }
    },
    searchAlgorithms(val) {
      if(val) {
        localStorage.setItem('search_algorithms', 1);
      } else {
        localStorage.setItem('search_algorithms', 0);
      }
    }
  },
  mounted() {
    this.$store.dispatch('ui/getFreeSpaceInfo');
    this.$store.dispatch('auth/getMe')
  }
}
</script>
<style scoped lang="scss">



.block{
  .panel-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .panelTitle{
    padding-bottom: 0.7em;
  }
}

.block>h4 {
  padding: 0.5em 0.75em 0.3em;
}
</style>
<style>
.settings .btn {
  text-transform: none !important;
}
</style>