<template>
  <form @submit.prevent="updateDefaultCountry">
    <b-form-group :label="$t('settings.Country')">
      <b-form-select v-model="selected" :options="modCountries"></b-form-select>
    </b-form-group>
    <!-- <b-field>
      <p class="control">
        <b-button type="is-default">
          {{ $t("settings.Country") }}
          <span
            v-if="country"
            :class="country.toLowerCase()"
            class="vti__flag"
          ></span>
        </b-button>
      </p>
      <b-autocomplete
        :data="filteredData"
        open-on-focus
        field="name"
        @focus="selected = ''"
        @blur="writeSelected()"
        v-model="selected"
        @select="
          (option) => {
            if (option) {
              country = option.value;
              selected = option.name;
            }
          }
        "
        expanded
      />
    </b-field> -->
  </form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CountrySelect",
  computed: {
    ...mapGetters("ui", ["countries"]),
    filteredData() {
      let newData = [];
      let countries = this.countries.map((item) => {
        return {
          name: `${item.dial_code} (${item.code}) ${item.name}`,
          value: item.code,
        };
      });
      newData = countries.filter((item) => {
        return item.name.toLowerCase().indexOf(this.selected) >= 0;
      });
      return newData;
    },
    modCountries() {
      return this.countries.map((item) => {
        return {
          text: `${item.dial_code} (${item.code}) ${item.name}`,
          value: item.code,
        };
      });
    }
  },
  data() {
    return {
      country: localStorage.getItem("country")
        ? JSON.parse(localStorage.getItem("country")).countryCode
        : "",
      selected: localStorage.getItem("country")
        ? JSON.parse(localStorage.getItem("country")).countryCode
        : "",
      preselected: "",
    };
  },
  methods: {
    updateDefaultCountry() {
      localStorage.setItem(
        "country",
        JSON.stringify({ countryCode: this.country })
      );
      localStorage.setItem("countryLabel", this.selected);
    },
    initSelect() {
      if (this.country && !this.selected.length) {
        let countries = this.countries.filter(
          (item) => item.code === this.country
        );
        this.selected = `${countries[0].dial_code} (${countries[0].code}) ${countries[0].name}`;
      }
    },
    writeSelected() {
      setTimeout(() => {
        if (this.selected.length === 0) {
          this.selected = this.preselected
        }
      }, 200);
    },
  },
  mounted() {
    this.initSelect();
  },
  watch: {
    selected(val, preval) {
      if (val === "") {
        this.preselected = preval;
      }
    },
    country() {
      this.updateDefaultCountry();
    },
  },
};
</script>

<style lang="scss">/*
.input-tel.input-phone-number {
  display: none !important;
}

.country-selector__input {
  border-radius: 4px !important;
}

form {
  width: 100%;
}

.field.has-addons .control:first-child:not(:only-child) .button:hover,
.field.has-addons .control:first-child:not(:only-child) .button:focus {
  outline: none;
  box-shadow: none;
  border-color: #dbdbdb;
}
.field.has-addons .control:first-child:not(:only-child) .button {
  cursor: default;
}
.vti__flag {
  display: inline-block;
}
.field.has-addons {
  width: 100%;
}
*/</style>